import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import mt5image from "../assets/images/mt5-image.jpg";

const MetaTrader5 = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Platforms" },
    { label: "Meta Trader 5" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Meta Trader 5" breadcrumbs={breadcrumbs} />
      <div className="benefits-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <img src={mt5image} alt="mt5" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Trading</span>
                  <h2 className="theme-title">
                    The World's Favorite Forex Trading Platform
                  </h2>
                  <p className="theme-description">
                    Advanced Tools for Advanced Trading
                  </p>
                  <p className="theme-description">
                    MetaTrader 5 (MT5) takes forex trading to the next level
                    with enhanced features for analysis and automated trading.
                  </p>
                  <p className="theme-description">
                    <b>Key Features:</b>
                  </p>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Comprehensive tools for market analysis
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Integrated trading signals and advanced charting
                              tools
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Mobile compatibility for trading anytime, anywhere
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              A seamless, integrated experience for professional
                              and beginner traders alike
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetaTrader5;
