import React from "react";
import PageTitle from "../components/common/PageTitle";

const TradingRules = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Resources" },
    { label: "Trading Rules" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Trading Rules" breadcrumbs={breadcrumbs} />
      <div className="benefits-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Trading</span>
                  <h2 className="theme-title">Forex Trading Overview</h2>
                  <p className="theme-description">
                    <b>MetaTrader 5 Features</b>
                  </p>
                  <p className="theme-description">
                    MetaTrader 5 provides robust tools for tracking open
                    positions, calculating profit/loss, and managing account
                    equity. It offers full back-office functionality, allowing
                    traders to monitor account metrics effectively. Despite the
                    automation, understanding manual calculations ensures better
                    decision-making and risk management.
                  </p>
                  <p className="theme-description">
                    <b>Major Currency Pairs</b>
                  </p>
                  <p className="theme-description">Popular pairs include:</p>
                  <div className="currency-pairs">
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered">
                        <tbody>
                          <tr>
                            <td>EUR/USD</td>
                            <td>(Euro vs. US Dollar)</td>
                          </tr>
                          <tr>
                            <td>GBP/USD</td>
                            <td>(UK Pound vs. US Dollar)</td>
                          </tr>
                          <tr>
                            <td>USD/JPY</td>
                            <td>(US Dollar vs. Japanese Yen)</td>
                          </tr>
                          <tr>
                            <td>USD/CHF</td>
                            <td>(US Dollar vs. Swiss Franc)</td>
                          </tr>
                          <tr>
                            <td>USD/CAD</td>
                            <td>(US Dollar vs. Canadian Dollar)</td>
                          </tr>
                          <tr>
                            <td>AUD/USD</td>
                            <td>(Australian Dollar vs. US Dollar)</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p className="theme-description">
                    These pairs dominate trading due to their high liquidity and
                    narrow spreads.
                  </p>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <p className="theme-description">
                            <b>Currency Mechanics</b>
                          </p>
                          <li>
                            <div className="text">
                              <b>Base Currency:</b> The first currency in a
                              pair; determines the trade size.
                            </div>
                          </li>
                          <li>
                            <div className="text">
                              <b>Quote Currency:</b> The second currency; used
                              for profit/loss calculations.
                            </div>
                          </li>
                          <li>
                            <div className="text">
                              <b>Example:</b> In EUR/USD, EUR is the base
                              currency, and USD is the quote.
                            </div>
                          </li>
                          <p className="theme-description">
                            <b>Margin and Leverage Requirements</b>
                          </p>
                          <li>
                            <div className="text">
                              <b>Margin:</b> A minimum of $1,000 is required for
                              1 standard lot (100,000 units), corresponding to
                              1% of trade size.
                            </div>
                          </li>
                          <li>
                            <div className="text">
                              <b>Leverage:</b> Allows traders to control large
                              positions with minimal capital, often 1:100.
                            </div>
                          </li>
                          <p className="theme-description">
                            ICM requires no maintenance margin but auto-closes
                            positions if equity falls below 5% of the margin
                            requirement.
                          </p>
                          <p className="theme-description">
                            <b>Profit/Loss Examples</b>
                          </p>
                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                              <p className="theme-description">
                                <b>EUR/USD Trade:</b>
                              </p>
                              <p className="theme-description">
                                <b>
                                  Buy 5 lots at 1.3450 → Sell at 1.3490 = $2,000
                                  profit.
                                </b>
                              </p>
                              <p className="theme-description">
                                Calculation: ( 1.3490 − 1.3450 ) × 5 × 100 ,
                              </p>
                              <p className="theme-description">
                                000 (1.3490−1.3450)×5×100,000.
                              </p>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                              <p className="theme-description">
                                <b>USD/CHF Trade:</b>
                              </p>
                              <p className="theme-description">
                                <b>
                                  Sell 3 lots at 0.8982 → Buy at 0.8924 = CHF
                                  1,740 profit.
                                </b>
                              </p>
                              <p className="theme-description">
                                <b>Converted to USD:</b>
                              </p>
                              <p className="theme-description">
                                Profit in CHF ÷ Close Price
                              </p>
                              <p className="theme-description">
                                Profit in CHF÷Close Price.
                              </p>
                            </div>
                          </div>
                          <p className="theme-description">
                            This structured approach ensures clarity in trade
                            planning and evaluation.
                          </p>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingRules;
