import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import ProductImg from "../assets/images/product-img.jpg";
import { Link } from "react-router-dom";

const PartnershipPrograms = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Partnership" },
    { label: "Partnership Programs" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Partnership Programs" breadcrumbs={breadcrumbs} />
      <div className="product-page-info-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="right-content">
                <div className="page-title mb-4">
                  <span>Partnership</span>
                  <h2 className="theme-title">
                    Introducing Brokers (IB) Program
                  </h2>
                  <p className="theme-description">
                    Our Introducing Brokers (IB) program is crafted to deliver
                    unmatched benefits, robust resources, and exceptional
                    support from our award-winning customer service team.
                  </p>
                  <p className="theme-description">
                    We prioritize flexibility by offering tailored solutions
                    that cater to the unique needs of each IB partner.
                  </p>
                  <p className="theme-description">
                    <b>What We Offer:</b>
                  </p>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Dedicated Support:</b> Personal account
                              managers to assist IB partners throughout the
                              client introduction process—from account setup to
                              utilizing the comprehensive MT5 Manager
                              back-office reporting suite.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Tailored Solutions:</b> Customizable
                              partnerships designed to meet your specific
                              requirements.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Seamless Onboarding:</b> A swift and efficient
                              account opening process, ensuring customer service
                              excellence.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Comprehensive Guidance:</b> Support for both
                              partners and their clients, ensuring a smooth
                              trading journey.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              UPFOREX is committed to providing IB partners with
                              the tools and assistance they need to thrive.
                            </div>
                          </li>
                          <p className="theme-description">
                            <b>Interested in Joining?</b>
                          </p>
                          <p className="theme-description">
                            Complete the form below or reach out to us at
                            ib@upforex.com. Let’s work together to achieve
                            success!
                          </p>
                          <Link type="button" className="btn" to="/contact-us">
                            Apply Now
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section gray-bg page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Program</span>
                  <h2 className="theme-title">Money Managers Program</h2>
                  <p className="theme-description">
                    Our Asset Managers Program is tailored for professional
                    traders, asset managers, and money managers who trade on
                    behalf of their clients or investors.
                  </p>
                  <p className="theme-description">
                    Through this program, investors or clients can sign Limited
                    Power of Attorney (LPOA) agreements, granting their chosen
                    asset managers the authority to trade on their behalf using
                    UPFOREX accounts.
                  </p>
                  <p className="theme-description">
                    <b>Empowering Asset Managers:</b>
                  </p>
                  <p className="theme-description">
                    Once the LPOA is in place, asset managers gain access to our
                    Multi-Account Manager (MAM) software, seamlessly integrated
                    with the MetaTrader 5 platform, enabling efficient and
                    professional management of client accounts.
                  </p>
                  <p className="theme-description">
                    <b>Investor/Client Benefits:</b>
                  </p>
                  <p className="theme-description">
                    Clients receive an investor password for view-only access to
                    their segregated accounts via the MetaTrader 5 platform,
                    ensuring transparency and peace of mind.
                  </p>
                  <p className="theme-description">
                    <b>Program Features:</b>
                  </p>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Unlimited Trading Accounts:</b> Manage multiple
                              accounts with ease.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>STP on Master Account:</b> Bulk order execution
                              with instant allocation to sub-accounts.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Flexible Allocation Methods:</b> Choose from 10
                              methods, including Lot, Percentage, and
                              Proportional.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Advanced Trading Options:</b> Full, Mini, and
                              Micro Lot accounts for maximum allocation
                              flexibility.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Streamlined Group Orders:</b> Execute trades
                              from a centralized control screen.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Partial Order Closures:</b> Execute partial
                              closures directly from the master account.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Comprehensive Functionality:</b> Includes Stop
                              Loss, Take Profit, Pending Orders, and EA trading
                              capabilities.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Detailed Reporting:</b> View output reports for
                              each sub-account.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Real-Time Monitoring:</b> Market watch and live
                              order management, including P&L tracking, within
                              MAM.
                            </div>
                          </li>
                          <p className="theme-description">
                            <b>Interested in Joining?</b>
                          </p>
                          <p className="theme-description">
                            Explore our tailored solutions for asset management
                            professionals. Complete the form below or contact us
                            at ib@upforex.com to get started.
                          </p>
                          <Link type="button" className="btn" to="/contact-us">
                            Apply Now
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="product-page-info-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="right-content">
                <div className="page-title mb-4">
                  <span>Education</span>
                  <h2 className="theme-title">Education Partners Program</h2>
                  <p className="theme-description">
                    At UPFOREX, we value the role of education in empowering
                    clients with the knowledge, skills, and insights needed to
                    navigate the financial markets confidently.
                  </p>
                  <p className="theme-description">
                    Our Education Partners Program is designed to collaborate
                    with those dedicated to education and training, particularly
                    for beginners entering the world of trading.
                  </p>
                  <p className="theme-description">
                    <b>Who Can Partner With Us?</b>
                  </p>
                  <p className="theme-description">
                    <b>We're excited to work with:</b>
                  </p>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Educational centers focused on financial markets
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Websites or blogs providing trading-related
                              content
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Organizations hosting training seminars or
                              webinars
                            </div>
                          </li>
                          <p className="theme-description">
                            <b>Why Partner with UPFOREX?</b>
                          </p>
                          <p className="theme-description">
                            By joining our Education Partners Program, you'll
                            gain access to resources and support that can
                            enhance your educational initiatives while creating
                            mutually beneficial opportunities.
                          </p>
                          <p className="theme-description">
                            <b>Interested in Partnering?</b>
                          </p>
                          <p className="theme-description">
                            Fill out the form below or reach out to us at
                            ib@upforex.com. Let's build a partnership that
                            drives growth and knowledge!
                          </p>
                          <Link type="button" className="btn" to="/contact-us">
                            Apply Now
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section gray-bg page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Program</span>
                  <h2 className="theme-title">Finfluencer Program</h2>
                  <p className="theme-description">
                    At UPFOREX, we recognize the influence of content creators
                    in shaping financial awareness and empowering individuals to
                    explore the financial markets.
                  </p>
                  <p className="theme-description">
                    Our Finfluencer Program is tailored to collaborate with
                    influencers who are passionate about trading, investing, and
                    educating their audience on financial topics.
                  </p>
                  <p className="theme-description">
                    <b>Who Can Partner With Us?</b>
                  </p>
                  <p className="theme-description">
                    <b>We're excited to collaborate with:</b>
                  </p>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Social media influencers creating trading and
                              financial content
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Financial bloggers and vloggers
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Creators hosting live sessions, podcasts, or
                              webinars on financial topics
                            </div>
                          </li>
                          <p className="theme-description">
                            <b>Why Partner with UPFOREX?</b>
                          </p>
                          <p className="theme-description">
                            By joining our Finfluencer Program, you’ll gain
                            access to exclusive resources, support, and tools to
                            create impactful content while earning rewards for
                            driving engagement and awareness.
                          </p>
                          <p className="theme-description">
                            <b>Interested in Partnering?</b>
                          </p>
                          <p className="theme-description">
                            Complete the form below or contact us at
                            ib@upforex.com. Let's create content that inspires
                            and educates!
                          </p>
                          <Link type="button" className="btn" to="/contact-us">
                            Apply Now
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="product-page-info-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="right-content">
                <div className="page-title mb-4">
                  <span>Program</span>
                  <h2 className="theme-title">Country Partners Program</h2>
                  <p className="theme-description">
                    At UPFOREX, our multinational growth and extensive client
                    base have allowed us to form successful partnerships
                    worldwide. We understand the value of working closely with
                    local partners to provide tailored Country client support.
                  </p>
                  <p className="theme-description">
                    <b>Who Can Join?</b>
                  </p>
                  <p className="theme-description">
                    <b>
                      Our Country Partners Program is ideal for individuals and
                      organizations with:
                    </b>
                  </p>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Strong connections within their local trading
                              community
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              In-depth knowledge of the online trading markets
                              in their region, country, or city
                            </div>
                          </li>
                          <p className="theme-description">
                            <b>Why Partner with UPFOREX?</b>
                          </p>
                          <p className="theme-description">
                            By becoming a Country partner of a trusted global
                            broker, you’ll gain the ability to offer clients
                            access to:
                          </p>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Cutting-edge trading technology
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              A premium trading experience with industry-leading
                              conditions
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Support from a global team committed to success
                            </div>
                          </li>
                          <p className="theme-description">
                            <b>Interested in Becoming a Country Partner?</b>
                          </p>
                          <p className="theme-description">
                            Complete the form below or contact us at
                            ib@upforex.com to explore this opportunity.
                          </p>
                          <Link type="button" className="btn" to="/contact-us">
                            Apply Now
                          </Link>
                          <p className="theme-description mt-3">
                            <b>Institutional Services</b>
                          </p>
                          <p className="theme-description">
                            Our Institutional Services are designed for
                            institutional clients seeking direct access to
                            flexible liquidity and efficient clearing solutions.
                          </p>
                          <p className="theme-description">
                            <b>Who Can Benefit?</b>
                          </p>
                          <p className="theme-description">
                            This option is tailored for institutional clients
                            who:
                          </p>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Require access to deep liquidity resources
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Demand advanced technology for ultra-fast trade
                              execution
                            </div>
                          </li>
                          <p className="theme-description">
                            <b>Why Choose UPFOREX Institutional Services?</b>
                          </p>
                          <p className="theme-description">
                            Partnering with UPFOREX ensures access to:
                          </p>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Scalable liquidity solutions to match your trading
                              needs
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Advanced tools for seamless execution in a
                              fast-paced trading environment
                            </div>
                          </li>
                          <p className="theme-description">
                            <b>Interested in Our Institutional Services?</b>
                          </p>
                          <p className="theme-description">
                            Fill out the form below or contact us at
                            ib@upforex.com to learn more.
                          </p>
                          <Link type="button" className="btn" to="/contact-us">
                            Apply Now
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section gray-bg page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Program</span>
                  <h2 className="theme-title">Social/Copy Trading Program</h2>
                  <p className="theme-description">
                    At UPFOREX, we believe in empowering traders by connecting
                    them with proven strategies and experienced professionals.
                    Our Social/Copy Trading Program is designed to bridge the
                    gap between experienced traders and those looking to learn,
                    follow, or replicate successful trading strategies.
                  </p>
                  <p className="theme-description">
                    <b>Who Can Join?</b>
                  </p>
                  <p className="theme-description">
                    <b>Our program is ideal for:</b>
                  </p>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Professional traders who want to share their
                              strategies with a wider audience while earning
                              additional income.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              New or intermediate traders who wish to follow and
                              copy the trades of seasoned professionals.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Influencers or mentors looking to expand their
                              reach in the trading community.
                            </div>
                          </li>
                          <p className="theme-description">
                            <b>Why Partner with UPFOREX?</b>
                          </p>
                          <p className="theme-description">
                            By participating in our Social/Copy Trading Program,
                            you’ll gain access to:
                          </p>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Advanced Copy Trading Platforms:</b> Seamlessly
                              integrate with tools like MetaTrader 5 for
                              efficient strategy sharing.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Performance Tracking:</b> Transparent reporting
                              and metrics to showcase trading success.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Flexible Earning Potential:</b> Earn
                              commissions as others copy your trades or follow
                              your recommendations.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Community Engagement:</b> Build your reputation
                              in a trusted global trading network.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Custom Support:</b> Dedicated assistance to
                              help you grow and manage your trading influence.
                            </div>
                          </li>
                          <p className="theme-description">
                            <b>Empower Your Trading Journey</b>
                          </p>
                          <p className="theme-description">
                            Whether you’re an experienced trader or someone
                            looking to follow proven strategies, our Social/Copy
                            Trading Program offers the tools, technology, and
                            support you need to succeed.
                          </p>
                          <p className="theme-description">
                            <b>Interested in Joining?</b>
                          </p>
                          <p className="theme-description">
                            Complete the form below or contact us at
                            ib@upforex.com to get started.
                          </p>
                          <Link type="button" className="btn" to="/contact-us">
                            Apply Now
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnershipPrograms;
