import "./assets/scss/custom.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/common/ScrollToTop";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import Forex from "./components/Forex";
import UsStocks from "./components/UsStocks";
import PreciousMetal from "./components/PreciousMetal";
import IndexFuture from "./components/IndexFuture";
import EnergyFuture from "./components/EnergyFuture";
import ContractSpecification from "./components/ContractSpecification";
import CryptocurrenciesCfd from "./components/CryptocurrenciesCfd";
import Accounts from "./components/Accounts";
import AccountTypes from "./components/AccountTypes";
import MetaTrader4 from "./components/MetaTrader4";
import MetaTrader5 from "./components/MetaTrader5";
import UpforexAgent from "./components/UpforexAgent";
import PartnershipPrograms from "./components/PartnershipPrograms";
import IntroductionToForex from "./components/IntroductionToForex";
import TradingRules from "./components/TradingRules";
import TradingGlossary from "./components/TradingGlossary";
import RiskDisclosure from "./components/RiskDisclosure";
import AntiMoneyLaundering from "./components/AntiMoneyLaundering";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Disclaimer from "./components/Disclaimer";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <div className="main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/products/forex" element={<Forex />} />
          <Route path="/products/us-stocks" element={<UsStocks />} />
          <Route path="/products/precious-metal" element={<PreciousMetal />} />
          <Route path="/products/index-future" element={<IndexFuture />} />
          <Route path="/products/energy-future" element={<EnergyFuture />} />
          <Route
            path="/products/contract-specification"
            element={<ContractSpecification />}
          />
          <Route
            path="/products/cryptocurrencies-cfd"
            element={<CryptocurrenciesCfd />}
          />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/accounts/account-types" element={<AccountTypes />} />
          <Route path="/platforms/mt4" element={<MetaTrader4 />} />
          <Route path="/platforms/mt5" element={<MetaTrader5 />} />
          <Route path="/partnership/upforex-agent" element={<UpforexAgent />} />
          <Route
            path="/partnership/partnership-programs"
            element={<PartnershipPrograms />}
          />
          <Route
            path="/resources/introduction-to-forex"
            element={<IntroductionToForex />}
          />
          <Route path="/resources/trading-rules" element={<TradingRules />} />
          <Route
            path="/resources/trading-glossary"
            element={<TradingGlossary />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/risk-disclosure" element={<RiskDisclosure />} />
          <Route
            path="/anti-money-laundering"
            element={<AntiMoneyLaundering />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
