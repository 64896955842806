import React from "react";
import TestimonialCarousel from "../components/common/TestimonialCarousel";
import EnhanceTool from "../assets/images/enhance-tools.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import OpenBook from "../assets/images/open-book.svg";
import FlashExecution from "../assets/images/flash-execution.svg";
import NoCommission from "../assets/images/no-commission.svg";
import aboutImg from "../assets/images/about-img.jpg";
import imageShape from "../assets/images/image-shape.png";
import calculater from "../assets/images/calculater.svg";
import sliderTool from "../assets/images/slider-tool.svg";
import survey from "../assets/images/survey.svg";
import learningSupport from "../assets/images/learning-support.svg";
import graph from "../assets/images/graph-bg.png";
import cryptoExchange from "../assets/images/crypto-exchange.png";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import leading from "../assets/images/leading.svg";
import years from "../assets/images/years.svg";
import orders from "../assets/images/orders.svg";
import customerSuport from "../assets/images/customer-support.svg";
import appStore from "../assets/images/in-app-store.svg";
import googleStore from "../assets/images/in-google-play.svg";
import mobileApp from "../assets/images/mobile-app.png";
import blogAvtar from "../assets/images/blog-avtar.png";
import testimonialAvatar from "../assets/images/testimonial-avatar.png";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import blogTwo from "../assets/images/blog-2.jpg";
import blogThree from "../assets/images/blog-3.jpg";
import fastExecution from "../assets/images/fast-execution.jpg";
const Carousel = [
  {
    description:
      "I was impressed by the services, lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia,Qui mollit cupidatat.",
    name: "Angela Nannenhorn",
    location: "from United Kingdom",
    image: testimonialAvatar,
  },
  {
    description:
      "The team provided excellent support. Lorem ipsum is placeholder text commonly used to demonstrate the visual form of a document.",
    name: "John Doe",
    location: "from USA",
    image: testimonialAvatar,
  },
  {
    description:
      "Amazing experience. Lorem ipsum is placeholder text used to demonstrate the appearance of different elements.",
    name: "Emma Watson",
    location: "from Canada",
    image: testimonialAvatar,
  },
];

const sliderSettings = {
  dots: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Home = () => {
  return (
    <div className="home">
      <div className="banner">
        <div className="container">
          <div className="slider-main-box">
            <div className="slider-inner">
              <div className="contain-box">
                <h1 className="theme-title">
                  New Revolutionary
                  <span className="text-link">Smart Trading&nbsp;</span>for a
                  Global Market Edge
                </h1>
                <p className="theme-description">
                  Trade Forex, US Stocks, Precious Metal, Index Future, Energy
                  Future and Crypto Currencies CFD from your Upforex Trading
                  Account
                </p>
                <div className="btn-box">
                  <button type="button" class="discover-btn">
                    Discover Features
                  </button>
                </div>
              </div>
            </div>
            <div className="slider-bottom">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="inner-main">
                    <div className="img-box">
                      <img src={EnhanceTool} alt="Enhance-Tool" />
                    </div>
                    <div className="content-box">
                      <h3 className="title">Enhanced Tools</h3>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="inner-main">
                    <div className="img-box">
                      <img src={OpenBook} alt="trading-guides" />
                    </div>
                    <div className="content-box">
                      <h3 className="title">Trading Guides</h3>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="inner-main">
                    <div className="img-box">
                      <img src={FlashExecution} alt="fast-execution" />
                    </div>
                    <div className="content-box">
                      <h3 className="title">Fast execution</h3>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="inner-main">
                    <div className="img-box">
                      <img src={NoCommission} alt="no-commission" />
                    </div>
                    <div className="content-box">
                      <h3 className="title">0% Commission</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area page-padding-big">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-about">
                <img src={aboutImg} alt="shape" />
                <img src={imageShape} alt="shape" className="shape-top" />
                <img src={imageShape} alt="shape" className="shape-bottom" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-about">
                <div className="page-title mb-4">
                  <span>Fast execution, low latency</span>
                  <h2 className="theme-title">
                    Your premium choice for trading currencies & stocks online
                  </h2>
                  <p className="theme-description">
                    Harness the power of technology to make a quicker, smarter
                    and more precise decision on CFD currency pairs, stocks,
                    commodities and more
                  </p>
                </div>
                <div className="data-box">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="inner-about">
                        <div className="img-box">
                          <img src={calculater} alt="cal" />
                        </div>
                        <p className="theme-description">Trading calculators</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="inner-about">
                        <div className="img-box">
                          <img src={sliderTool} alt="cal" />
                        </div>
                        <p className="theme-description">Market analysis</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="inner-about">
                        <div className="img-box">
                          <img src={survey} alt="cal" />
                        </div>
                        <p className="theme-description">Market reviews</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="inner-about">
                        <div className="img-box">
                          <img src={learningSupport} alt="cal" />
                        </div>
                        <p className="theme-description">Trading academy</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fast-execution-area page-padding">
        <img src={graph} alt="graph-img" className="graph-img" />
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-about">
                <div className="page-title mb-4">
                  <span>Ultra-Fast Execution with Tight Spreads</span>
                  <h2 className="theme-title">
                    Experience exceptional market conditions designed for
                    traders like you.
                  </h2>
                  <p className="theme-description">
                    Best market prices available so you can receive excellent
                    conditions.
                  </p>
                </div>
                <div className="data-box">
                  <div className="equity-list">
                    <ul>
                      <li>
                        <div className="icon">
                          <FontAwesomeIcon icon={faChevronCircleRight} />
                        </div>
                        <div className="text">
                          <b>Negative balance protection</b> Trade with
                          confidence, knowing your risks are managed.
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <FontAwesomeIcon icon={faChevronCircleRight} />
                        </div>
                        <div className="text">
                          <b>Secured Client Funds:</b> Your funds are protected
                          with the highest standards of security.
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <FontAwesomeIcon icon={faChevronCircleRight} />
                        </div>
                        <div className="text">
                          <b>Instant Deposits & Quick Withdrawals:</b> Enjoy
                          seamless transactions for a hassle-free trading
                          experience.
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-execution">
                {/* <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="coin-box">
                          <img src={cryptoExchange} alt="coin" />
                          <div className="coin-name">
                            EURUSD
                            <span>Euro / U.S. Dollar</span>
                          </div>
                        </td>
                        <td>1.04530</td>
                        <td className="value-box">
                          <span className="text-success">+0.22%</span>
                          <span className="text-success">+0.00225 </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="coin-box">
                          <img src={cryptoExchange} alt="coin" />
                          <div className="coin-name">
                            EURUSD
                            <span>Euro / U.S. Dollar</span>
                          </div>
                        </td>
                        <td>1.04530</td>
                        <td className="value-box">
                          <span className="text-success">+0.22%</span>
                          <span className="text-success">+0.00225 </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="coin-box">
                          <img src={cryptoExchange} alt="coin" />
                          <div className="coin-name">
                            EURUSD
                            <span>Euro / U.S. Dollar</span>
                          </div>
                        </td>
                        <td>1.04530</td>
                        <td className="value-box">
                          <span className="text-success">+0.22%</span>
                          <span className="text-success">+0.00225 </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="coin-box">
                          <img src={cryptoExchange} alt="coin" />
                          <div className="coin-name">
                            EURUSD
                            <span>Euro / U.S. Dollar</span>
                          </div>
                        </td>
                        <td>1.04530</td>
                        <td className="value-box">
                          <span className="text-success">+0.22%</span>
                          <span className="text-success">+0.00225 </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="coin-box">
                          <img src={cryptoExchange} alt="coin" />
                          <div className="coin-name">
                            EURUSD
                            <span>Euro / U.S. Dollar</span>
                          </div>
                        </td>
                        <td>1.04530</td>
                        <td className="value-box">
                          <span className="text-success">+0.22%</span>
                          <span className="text-success">+0.00225 </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="coin-box">
                          <img src={cryptoExchange} alt="coin" />
                          <div className="coin-name">
                            EURUSD
                            <span>Euro / U.S. Dollar</span>
                          </div>
                        </td>
                        <td>1.04530</td>
                        <td className="value-box">
                          <span className="text-success">+0.22%</span>
                          <span className="text-success">+0.00225 </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
                <img src={fastExecution} alt="fast-execution" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="popular-products page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title text-center mb-0 page-center">
                {/* <span>popular markets</span> */}
                <h2 className="theme-title">
                  Trade Smarter Across Global Assets
                </h2>
                <p className="theme-description">
                  Find your next trade with access to a wide range of markets.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="popular-box">
                <h3 className="theme-title">Forex</h3>
                <p className="theme-decsription">
                  Access 300+ futures covering equity indices, energy, metals,
                  agriculture, rates, commodities, and more.
                </p>
                <div className="popular-bottom clearfix">
                  <div className="inner-box">
                    <span className="theme-bg-color">FX</span>
                  </div>
                  <div className="link-box">
                    <a href="#" className="text-link">
                      Explore Now
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="popular-box">
                <h3 className="theme-title">US Stocks</h3>
                <p className="theme-decsription">
                  Access 19,000+ stocks across core and emerging markets on 40+
                  exchanges worldwide.
                </p>
                <div className="popular-bottom clearfix">
                  <div className="inner-box">
                    <span className="theme-bg-color">EQ</span>
                  </div>
                  <div className="link-box">
                    <a href="#" className="text-link">
                      Explore Now
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="popular-box">
                <h3 className="theme-title">Precious Metal</h3>
                <p className="theme-decsription">
                  Trade gold, silver, and other precious metals worldwide for
                  financial stability and portfolio diversification.
                </p>
                <div className="popular-bottom clearfix">
                  <div className="inner-box">
                    <span className="theme-bg-color">PM</span>
                  </div>
                  <div className="link-box">
                    <a href="#" className="text-link">
                      Explore Now
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="popular-box">
                <h3 className="theme-title"> Index and Energy Future</h3>
                <p className="theme-decsription">
                  Access 300+ futures covering equity indices, energy, metals,
                  agriculture, rates, commodities, and more.
                </p>
                <div className="popular-bottom clearfix">
                  <div className="inner-box">
                    <span className="theme-bg-color">BO</span>
                  </div>
                  <div className="link-box">
                    <a href="#" className="text-link">
                      Explore Now
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="popular-box">
                <h3 className="theme-title">Cryptocurrencies CFD</h3>
                <p className="theme-decsription">
                  Access 1,200+ listed options across equities, indices,
                  interest rates, energy, metals, and commodities.
                </p>
                <div className="popular-bottom clearfix">
                  <div className="inner-box">
                    <span className="theme-bg-color">CFD</span>
                  </div>
                  <div className="link-box">
                    <a href="#" className="text-link">
                      Explore Now
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="leading-area page-padding bg-overlay">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="get-start-box">
                <h3 className="theme-title mb-0">
                  <span className="text-link">UpForex:</span> Your Gateway to
                  New Revolutionary Smart Trading
                </h3>
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <div className="leading-box">
                      <div className="img-box">
                        <img src={leading} alt="leading-img" />
                      </div>
                      <div className="data-box">
                        <h4 className="theme-title">
                          Built for Modern Traders
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <div className="leading-box">
                      <div className="img-box">
                        <img src={years} alt="leading-img" />
                      </div>
                      <div className="data-box">
                        <h4 className="theme-title">
                          Committed to Transparency and Security
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <div className="leading-box">
                      <div className="img-box">
                        <img src={orders} alt="leading-img" />
                      </div>
                      <div className="data-box">
                        <h4 className="theme-title">
                          Next-Gen Trading Infrastructure
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <div className="leading-box">
                      <div className="img-box">
                        <img src={customerSuport} alt="leading-img" />
                      </div>
                      <div className="data-box">
                        <h4 className="theme-title">
                          Always Here When You Need Us
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="trader-join-area page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title mb-0 text-center">
                <span>traders Join</span>
                <h2 className="theme-title mb-0">
                  More than 23,000 traders joined
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <TestimonialCarousel
              Carousel={Carousel}
              settings={sliderSettings}
              isHomePage={true}
            />
          </div>
        </div>
      </div> */}
      <div className="mobile-app-area page-padding">
        <div className="container">
          <div className="chalange-main-box">
            <div className="box-left">
              <div className="page-title mb-0">
                <span>MT5 Mobile App</span>
                <h2 className="theme-title">
                  Trade Anywhere, Anytime with MetaTrader 5 Mobile App
                </h2>
                <p className="theme-description">
                  Take your trading to the next level with the powerful
                  <b>MetaTrader 5 (MT5) Mobile App.</b> Designed for traders on
                  the move, the app offers a seamless and intuitive trading
                  experience at your fingertips.
                </p>
                <div className="equity-list">
                  <ul>
                    <li>
                      <div className="icon">
                        <FontAwesomeIcon icon={faChevronCircleRight} />
                      </div>
                      <div className="text">
                        <b>Advanced Charting Tools:</b> Access real-time charts
                        with multiple timeframes and built-in technical
                        indicators.
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <FontAwesomeIcon icon={faChevronCircleRight} />
                      </div>
                      <div className="text">
                        <b>Multi-Asset Trading:</b> Trade Forex, stocks,
                        indices, commodities, and cryptocurrencies from a single
                        platform.
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <FontAwesomeIcon icon={faChevronCircleRight} />
                      </div>
                      <div className="text">
                        <b>Full Account Control:</b> Manage your trades, place
                        orders, and monitor your portfolio effortlessly.
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <FontAwesomeIcon icon={faChevronCircleRight} />
                      </div>
                      <div className="text">
                        <b>Real-Time Market Data:</b> Stay updated with live
                        prices and news for informed decision-making.
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <FontAwesomeIcon icon={faChevronCircleRight} />
                      </div>
                      <div className="text">
                        <b>Secure & User-Friendly:</b> Enjoy a safe and
                        responsive trading environment, optimized for mobile.
                      </div>
                    </li>
                  </ul>
                </div>
                <p className="theme-description">
                  Whether you're a beginner or a seasoned trader, the MT5 Mobile
                  App ensures you never miss a trading opportunity.{" "}
                  <b>Download now and trade smarter, faster, and better!</b>
                </p>
                <div className="btn-box app-btn">
                  <ul className="p-0">
                    <li>
                      <a
                        href="https://apps.apple.com/in/app/metatrader-5/id413251709"
                        target="_blank"
                      >
                        <img src={appStore} alt="ios-btn" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&pcampaignid=web_share"
                        target="_blank"
                      >
                        <img src={googleStore} alt="android-btn" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="box-right">
              <div className="app-img-box">
                <img src={mobileApp} alt="Mobile-app-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="blog-area page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title text-center page-center">
                <span>Blog</span>
                <h2 className="theme-title">Our Blog</h2>
                <p className="theme-description">
                  Some believe you must choose between an online broker and a
                  wealth management firm. At our company, you don't need to
                  compromise. Whether you invest on your own, with an advisor,
                  or a little of both — we can support you.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="blog-main-box">
                <div className="img-box">
                  <img src={aboutImg} alt="about-img" />
                  <span>Markets</span>
                </div>
                <div className="content-box">
                  <h4 className="theme-title">
                    <a href="#">Sony buys $400m stake in China's Bilibili</a>
                  </h4>
                  <p className="theme-description">
                    After a series of previous projects together, Sony has
                    secured a ...
                  </p>
                </div>
                <div className="blog-bottom clearfix">
                  <div className="left-box">
                    <div className="blog-imgbox">
                      <span>
                        <img src={blogAvtar} alt="blog-avtar" />
                        Sachin Diwar
                      </span>
                    </div>
                  </div>
                  <div className="right-box">
                    <span>
                      <FontAwesomeIcon icon={faCalendar} />
                      October 19, 2021
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="blog-main-box">
                <div className="img-box">
                  <img src={blogTwo} alt="about-img" />
                  <span>Auto Motive</span>
                </div>
                <div className="content-box">
                  <h4 className="theme-title">
                    <a href="#">Sony buys $400m stake in China's Bilibili</a>
                  </h4>
                  <p className="theme-description">
                    After a series of previous projects together, Sony has
                    secured a ...
                  </p>
                </div>
                <div className="blog-bottom clearfix">
                  <div className="left-box">
                    <div className="blog-imgbox">
                      <span>
                        <img src={blogAvtar} alt="blog-avtar" />
                        Sachin Diwar
                      </span>
                    </div>
                  </div>
                  <div className="right-box">
                    <span>
                      <FontAwesomeIcon icon={faCalendar} />
                      October 19, 2021
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="blog-main-box">
                <div className="img-box">
                  <img src={blogThree} alt="about-img" />
                  <span>Investment</span>
                </div>
                <div className="content-box">
                  <h4 className="theme-title">
                    <a href="#">Sony buys $400m stake in China's Bilibili</a>
                  </h4>
                  <p className="theme-description">
                    After a series of previous projects together, Sony has
                    secured a ...
                  </p>
                </div>
                <div className="blog-bottom clearfix">
                  <div className="left-box">
                    <div className="blog-imgbox">
                      <span>
                        <img src={blogAvtar} alt="blog-avtar" />
                        Sachin Diwar
                      </span>
                    </div>
                  </div>
                  <div className="right-box">
                    <span>
                      <FontAwesomeIcon icon={faCalendar} />
                      October 19, 2021
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="get-start-area page-padding bg-overlay">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="get-start-box">
                <h3 className="theme-title">Unlock Access to Global Markets</h3>
                <p className="theme-description">
                  Discover the Power of Global Trading from Single Account&nbsp;
                  <a href="#">Open Your Account </a>
                </p>
                <div className="btn-box">
                  <a
                    href="https://apps.apple.com/in/app/metatrader-5/id413251709"
                    target="_blank"
                  >
                    <img src={appStore} alt="ios-btn" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&pcampaignid=web_share"
                    target="_blank"
                  >
                    <img src={googleStore} alt="android-btn" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
