import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faRegistered } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import AboutImg from "../assets/images/about-2.jpg";

const AboutUs = () => {
  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "About Us" }];
  return (
    <div className="about-page">
      <PageTitle title="About Us" breadcrumbs={breadcrumbs} />
      <div className="about-info-area page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-about">
                <img src={AboutImg} alt="about-us" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-about">
                <div className="page-title mb-4">
                  <span>About Us</span>
                  <h2 className="theme-title">Welcome to Upforex.com</h2>
                  <p className="theme-description">
                    Welcome to Upforex.com, a leading Forex brokerage dedicated
                    to equipping traders worldwide with the tools they need. We
                    offer exceptional knowledge, and an extensive selection of
                    trading accounts tailored for Retail Traders, Money
                    Managers, Institutional Traders, Risk Managers, and
                    Investment Companies. At Upforex.com, your experience goes
                    beyond trading; you gain entry to a reliable platform
                    crafted to uphold the highest standards in security,
                    transparency, and performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="regulation-compliance-area gray-bg page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title">
                <span>trading environment</span>
                <h2 className="theme-title">Regulation and Compliance</h2>
                <p className="theme-description">
                  At UpForex, we are committed to delivering a secure,
                  innovative, and user-friendly trading environment to our
                  clients worldwide. Established in 2024, UpForex aims to
                  provide traders with the tools, resources, and support they
                  need to succeed in the global financial markets.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="content">
                <p className="theme-description">
                  <b>Regulation and Compliance</b>
                </p>
                <p className="theme-description">
                  We are proud to operate as a registered and compliant entity.
                  Up Forex Ltd is incorporated in Saint Lucia under registration
                  number 2024-00700. Our company was officially incorporated on
                  25th November 2024, ensuring adherence to the laws and
                  regulations governing the financial trading industry.
                </p>
                <p className="theme-description">
                  As part of our commitment to maintaining high operational
                  standards, we operate transparently and follow all applicable
                  regulatory requirements in the jurisdictions we serve
                  (excluding the USA and other sanctioned countries).
                </p>
                <p className="theme-description">
                  <b>Our Mission</b>
                </p>
                <p className="theme-description">
                  Our mission is to empower traders by offering:
                </p>
                <ul className="m-0">
                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                    <div className="text">
                      Competitive trading conditions with leverage up to 1:300.
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                    <div className="text">
                      A wide range of instruments including Forex, precious
                      metals, energy markets, index futures, US stock indices,
                      and cryptocurrencies.
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                    <div className="text">
                      Cutting-edge technology to ensure seamless trading
                      experiences.
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="why-choose-area page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title text-center page-center">
                <span>Trusted Choice</span>
                <h2 className="theme-title">Why Choose Upforex.com?</h2>
                <p className="theme-description">
                  Dedicated to delivering excellence every single time.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="choose-box">
                <div className="top-box">
                  <FontAwesomeIcon icon={faDesktop} />
                </div>
                <h4 className="theme-title">Ultra-Modern Trading Platform</h4>
                <p className="theme-description mb-0">
                  Our ultra-modern platform provides powerful analytical tools,
                  live data feeds, and ability to access from various devices,
                  ensuring an exceptional trading experience.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="choose-box">
                <div className="top-box">
                  <FontAwesomeIcon icon={faHeadset} />
                </div>
                <h4 className="theme-title">Dedicated Support</h4>
                <p className="theme-description mb-0">
                  Our dedicated team of specialists is available 24/7, offering
                  insights and support whenever you need assistance.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="choose-box">
                <div className="top-box">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <h4 className="theme-title">User Friendly Account Options</h4>
                <p className="theme-description mb-0">
                  We offer solutions designed to meet various trading
                  requirements, whether you're a beginner retail trader or a
                  seasoned institutional investor.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="choose-box">
                <div className="top-box">
                  <FontAwesomeIcon icon={faRegistered} />
                </div>
                <h4 className="theme-title">Authorized and Transparent</h4>
                <p className="theme-description mb-0">
                  Operates under Saint Lucia's company regulations.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="choose-box">
                <div className="top-box">
                  <FontAwesomeIcon icon={faGlobe} />
                </div>
                <h4 className="theme-title">Global Access</h4>
                <p className="theme-description mb-0">
                  Supporting traders across the globe with a secure and
                  user-friendly platform.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="choose-box">
                <div className="top-box">
                  <FontAwesomeIcon icon={faLightbulb} />
                </div>
                <h4 className="theme-title">Innovative Trading Tools</h4>
                <p className="theme-description mb-0">
                  Offering customizable solutions to match diverse trading
                  needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
