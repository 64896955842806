import React, { useState, useEffect } from "react";
import ResponsiveDropdownMenu from "./ResponsiveDropdownMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header>
      <div className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="left-topbar">
                <ul className="p-0 m-0">
                  <li>
                    <FontAwesomeIcon icon={faPhone} />
                    <a href="tel:+97143342728">+97143342728</a>
                  </li>
                  <li className="email-div">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <a href="mailto:support@upforex.com">support@upforex.com</a>
                  </li>
                  <li>
                    <div className="mobile-social-icons">
                      <ul className="p-0 m-0">
                        <li>
                          <a href="#">
                            <FontAwesomeIcon icon={faFacebookF} />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <FontAwesomeIcon icon={faTwitter} />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <FontAwesomeIcon icon={faInstagram} />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <FontAwesomeIcon icon={faLinkedin} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="right-topbar">
                <ul className="p-0 m-0">
                  <li>
                    <Link
                      to="https://www.facebook.com/upforex9/"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://x.com/Forexup9" target="_blank">
                      <FontAwesomeIcon icon={faTwitter} />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/upforex9"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/upforex"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`menu-header ${scrolled ? "fixed-header" : ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ResponsiveDropdownMenu />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
