import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import cryptocurrenciescfd1 from "../assets/images/cryptocurrencies-cfd1.jpg";
import cryptocurrenciescfd2 from "../assets/images/cryptocurrencies-cfd2.jpg";

const CryptocurrenciesCfd = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Products" },
    { label: "Cryptocurrencies CFD" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Cryptocurrencies CFD" breadcrumbs={breadcrumbs} />
      <div className="product-page-info-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <img src={cryptocurrenciescfd1} alt="cryptocurrenciescfd" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <div className="page-title mb-4">
                  <span>Trading</span>
                  <h2 className="theme-title">
                    Cryptocurrency CFD Trading with UPFOREX
                  </h2>
                  <p className="theme-description">
                    Trading cryptocurrencies through Contracts for Difference
                    (CFDs) with UPFOREX offers low-latency execution and
                    competitive spreads. With an UPFOREX Direct Account or
                    UPFOREX Zero Account, you can trade with a margin
                    requirement of just 50%.
                  </p>
                  <p className="theme-description">
                    Now available on MetaTrader 5, you can trade seven major
                    cryptocurrencies, including Bitcoin, Ethereum, and Litecoin,
                    through a multi-regulated broker for a secure and efficient
                    trading experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section gray-bg page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Advantages</span>
                  <h2 className="theme-title">
                    Advantages of Cryptocurrency CFD Trading
                  </h2>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Direct Trading:</b> Trade without involving
                              intermediaries.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Optimized Capital:</b> Maximize returns with
                              efficient capital use.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Hedging Opportunities:</b> Protect your
                              portfolio with hedging strategies.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>No Expiry:</b> CFDs don't come with fixed
                              expiry dates, offering greater flexibility.
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Dual Market Profit Potential:</b> Earn from
                              both rising and falling markets.
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <img src={cryptocurrenciescfd2} alt="cryptocurrenciescfd" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="advantage-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title mb-4">
                <span>trade</span>
                <h2 className="theme-title">
                  Why Trade Cryptocurrency CFDs with UPFOREX?
                </h2>
              </div>
            </div>
          </div>
          <div className="advantage-list">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="advantage-box">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                  <div className="text">
                    <b>Fast Execution:</b> Enjoy rapid and reliable trade
                    processing.
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="advantage-box">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                  <div className="text">
                    <b>Competitive Spreads:</b> Benefit from low spreads for
                    cost-effective trading.
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="advantage-box">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                  <div className="text">
                    <b>50% Margin Requirement:</b> Trade with a reduced margin
                    for added leverage.
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="advantage-box">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                  <div className="text">
                    <b>Advanced Tools:</b> Access free indicators and analytical
                    tools.
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="advantage-box">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </div>
                  <div className="text">
                    <b>Cross-Platform Trading:</b> Trade seamlessly from PC or
                    mobile devices.
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="page-title mt-3">
                  <p className="theme-description">
                    Experience the dynamic cryptocurrency market with UPFOREX,
                    designed to provide you with an efficient, reliable, and
                    flexible trading environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CryptocurrenciesCfd;
