import React from "react";
import PageTitle from "../components/common/PageTitle";
import { Link } from "react-router-dom";

const Disclaimer = () => {
  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "Disclaimer" }];
  return (
    <div className="products-page-main">
      <PageTitle title="Disclaimer" breadcrumbs={breadcrumbs} />
      <div className="benefits-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Information</span>
                  <h2 className="theme-title">Disclaimer</h2>
                  <p className="theme-description">
                    <b>Risk statement:</b> An investment in derivatives may mean
                    investors may lose an amount even greater than their
                    original investment. Anyone wishing to invest in any of the
                    products mentioned in{" "}
                    <Link className="common-page-link" to="/">
                      {" "}
                      https://upforex.com{" "}
                    </Link>
                    should seek their own financial or professional advice.
                    Trading of securities, forex, stock market, commodities,
                    options and futures may not be suitable for everyone and
                    involves the risk of losing part or all of your money.
                    Trading in the financial markets has large potential
                    rewards, but also large potential risk. You must be aware of
                    the risks and be willing to accept them in order to invest
                    in the markets. Don't invest and trade with money which you
                    can't afford to lose. Forex Trading are not allowed in some
                    countries, before investing your money, make sure whether
                    your country is allowing this or not.
                  </p>
                  <p className="theme-description">
                    You are strongly advised to obtain independent financial,
                    legal and tax advice before proceeding with any currency or
                    spot metals trade. Nothing in this site should be read or
                    construed as constituting advice on the part of UP FOREX LTD
                    or any of its affiliates, directors, officers or employees.
                  </p>
                  <p className="theme-description">
                    <b>Restricted Regions:</b> UP FOREX LTD does not provide
                    services for citizens/residents of the United States, Cuba,
                    Iraq, Myanmar, North Korea, Sudan. The services of UP FOREX
                    LTD are not intended for distribution to, or use by, any
                    person in any country or jurisdiction where such
                    distribution or use would be contrary to local law or
                    regulation.
                  </p>
                  <p className="theme-description">OR</p>
                  <p className="theme-description">
                    <i>
                      Information on this site is not directed at residents in
                      any country or jurisdiction where such distribution or use
                      would be contrary to local law or regulation.
                    </i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
