import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import ProductImg from "../assets/images/product-img.jpg";
import forex2 from "../assets/images/forex-2.jpg";

const Forex = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Products" },
    { label: "Forex" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Forex" breadcrumbs={breadcrumbs} />
      <div className="product-page-info-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <img src={ProductImg} alt="about-us" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <div className="page-title mb-4">
                  <span>Forex</span>
                  <h2 className="theme-title">Forex Trading</h2>
                  <p className="theme-description">
                    The foreign exchange (forex) market is recognized as the
                    largest and most fluid globally, with daily trading $7
                    trillion. It functions as a virtual network where national
                    currencies are traded against one another. Participants in
                    the forex market are not confined to any specific physical
                    location or central exchange, as it operates under an Over
                    the Counter (OTC) system, allowing individuals to conduct
                    transactions directly without needing a thirdparty
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section gray-bg page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <div className="page-title">
                  <span>benefits</span>
                  <h2 className="theme-title">Benefits of Forex Trading</h2>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Absence of intermediaries
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Around-the-clock market access
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Strong market liquidity
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Trading without commissions
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Potential for profit in both declining and rising
                            markets
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Availability of high leverage
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Complimentary demo accounts
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Free access to news, charts, and analysis
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <img src={forex2} alt="about-us" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="advantage-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title mb-4">
                <span>advantages</span>
                <h2 className="theme-title">
                  What's the Advantage of Trading Forex with UPFOREX?
                </h2>
                <p className="theme-description">
                  UPFOREX provides access to over 40 currency pairs encompassing
                  major, minor, cross, and exotic Forex pairs.
                </p>
                <p className="theme-description">
                  Utilizing our sophisticated platforms, which operate on the
                  ECN/STP model, traders gain direct market access. This ensures
                  they can trade with minimal spreads, swift execution times,
                  and substantial liquidity, guaranteeing an optimal trading
                  experience, with complete transparency at all times.
                </p>
              </div>
            </div>
          </div>
          <div className="advantage-list">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">Direct market access to Forex</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">Zero commissions</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">
                    Competitive fixed or variable spreads
                  </h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">
                    Swift execution without any re-quotes
                  </h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">
                    Significant liquidity and market depth
                  </h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">
                    Trade using both PC and mobile devices
                  </h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">
                    Option to utilize expert advisors
                  </h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">
                    No trading restrictions for customers
                  </h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">Free VPS service available</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">High leverage options</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">Ability to trade micro lots</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">Swap-free accounts</h3>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="advantage-box mb-0">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <h3 className="theme-title">Access to US stocks</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forex;
