import React from "react";
import TestimonialCarousel from "../components/common/TestimonialCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import account2 from "../assets/images/account2.jpg";
import promotion1 from "../assets/images/promotion1.jpg";
import promotion2 from "../assets/images/promotion2.jpg";
import promotion3 from "../assets/images/promotion3.jpg";
import account1 from "../assets/images/account1.jpg";

const Carousel = [
  {
    image: promotion1,
    heading: "Trade with Confidence",
    button: "Learn More",
  },
  {
    image: promotion2,
    heading: "Exclusive Offers for New Clients!",
    button: "Discover More",
  },
  {
    image: promotion3,
    heading: "Upgrade Your Trading Experience Today",
    button: "Find Out More",
  },
];

const sliderSettings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Accounts = () => {
  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "Accounts" }];
  return (
    <div className="products-page-main">
      <PageTitle title="Accounts" breadcrumbs={breadcrumbs} />
      <div className="product-page-info-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <img src={account1} alt="account" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <div className="page-title mb-4">
                  <span>Accounts</span>
                  <h2 className="theme-title">Accounts</h2>
                  <p className="theme-description">
                    Managing your Upforex.com account is straightforward, and
                    this support page is here to guide you through your trading
                    journey.
                  </p>
                  <p className="theme-description">
                    With MetaTrader 5 (MT5), you can enjoy fast and competitive
                    pricing on a wide range of trading instruments, including:
                  </p>
                  <p className="theme-description">
                    <label className="w-100">
                      <b>Foreign Exchange (FX)</b>
                    </label>
                    <label className="w-100">
                      <b>CFD Contracts on Oil (WTI & Brent)</b>
                    </label>
                    <label className="w-100">
                      <b>Indices</b>
                    </label>
                    <label className="w-100">
                      <b>Precious Metals like Silver and Gold</b>
                    </label>
                  </p>
                  <p className="theme-description">
                    For more details, visit the Trading Products page. MT5 is
                    renowned as one of the most trusted and widely used trading
                    platforms in the industry, offering a seamless and reliable
                    trading experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section gray-bg page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Trade</span>
                  <h2 className="theme-title">Why Trade with Us?</h2>
                  <div className="benefits-list">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <ul className="m-0">
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Swift and secure account setup
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Streamlined client onboarding process
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Dedicated, segregated client accounts for added
                              safety
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Upforex Login:</b> Manage your account with
                              ease
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              <b>Upforex Cashier:</b> Seamless and fast funding
                              solutions
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="text">
                              Round-the-clock multilingual client support, 5
                              days a week
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right-content">
                <img src={account2} alt="account" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="advantage-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title mb-4">
                <span>FAQ</span>
                <h2 className="theme-title">Account FAQ</h2>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="advantage-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title mb-4">
                <span>Promotions</span>
                <h2 className="theme-title">Promotions</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <TestimonialCarousel
              Carousel={Carousel}
              settings={sliderSettings}
              isHomePage={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accounts;
