import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import {
  faLocationDot,
  faEnvelope,
  faPhone,
  faUser,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import Contact from "../assets/images/contact.jpg";
import imageShape from "../assets/images/image-shape.png";
import APIHelper from "../helpers/APIHelper";

const ContactUs = () => {
  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "Contact Us" }];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const validate = () => {
    const newErrors = {};

    // Name Validation
    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
    } else if (formData.name.length < 3) {
      newErrors.name = "Name must be at least 3 characters long.";
    } else if (formData.name.length > 100) {
      newErrors.name = "Name must not exceed 100 characters.";
    }

    // Email Validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please provide a valid email address.";
    }

    // Subject Validation
    if (!formData.subject.trim()) {
      newErrors.subject = "Subject is required.";
    } else if (formData.subject.length < 3) {
      newErrors.subject = "Subject must be at least 3 characters long.";
    } else if (formData.subject.length > 150) {
      newErrors.subject = "Subject must not exceed 150 characters.";
    }

    // Message Validation
    if (!formData.message.trim()) {
      newErrors.message = "Message is required.";
    } else if (formData.message.length < 10) {
      newErrors.message = "Message must be at least 10 characters long.";
    } else if (formData.message.length > 1000) {
      newErrors.message = "Message must not exceed 1000 characters.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage("");

    if (!validate()) {
      return;
    }

    setLoading(true);

    try {
      const response = await APIHelper.post("/contact/send", formData);
      setResponseMessage(response.message || "Message sent successfully!");
      setFormData({ name: "", email: "", subject: "", message: "" }); // Clear form
    } catch (error) {
      setResponseMessage(error.message || "Failed to send message.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="contact-page">
      <PageTitle title="Contact Us" breadcrumbs={breadcrumbs} />
      <div className="contact-top-area page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title text-center page-center">
                <span>Contact</span>
                <h2 className="theme-title">Contact Us</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="contact-top">
                <div className="icon-box">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <div className="content-box">
                  <h4 className="theme-title">Registered Office</h4>
                  <p className="theme-description office-address">
                    Ground Floor, Southeby Building, Rodney Village, Rodney Bay,
                    Gros-Islet, Saint Lucia
                  </p>
                </div>
                <div className="big-icon">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="contact-top">
                <div className="icon-box">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div className="content-box">
                  <a href="mailto:support@upforex.com">support@upforex.com</a>
                  <p className="theme-description">For Public Inquiries</p>
                </div>
                <div className="big-icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="contact-top">
                <div className="icon-box">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <div className="content-box">
                  <h4 className="theme-title">
                    <a href="tel:+97143342728">+97143342728</a>
                  </h4>
                  <p className="theme-description">MON - FRI, 9AM - 5PM</p>
                </div>
                <div className="big-icon">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-area page-padding border-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title text-center page-center">
                <span>Have a questions?</span>
                <h2 className="theme-title">Let's get in touch</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="left-contact">
                <form onSubmit={handleSubmit}>
                  <div className="theme-input-box">
                    <label>Full Name</label>
                    <div className="input-box">
                      <input
                        type="text"
                        className={`form-control theme-input ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        placeholder="Enter Full Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      <FontAwesomeIcon icon={faUser} />
                      {errors.name && (
                        <small className="text-danger">{errors.name}</small>
                      )}
                    </div>
                  </div>
                  <div className="theme-input-box">
                    <label>Email</label>
                    <div className="input-box">
                      <input
                        type="email"
                        className={`form-control theme-input ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        placeholder="Enter Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <FontAwesomeIcon icon={faEnvelope} />
                      {errors.email && (
                        <small className="text-danger">{errors.email}</small>
                      )}
                    </div>
                  </div>
                  <div className="theme-input-box">
                    <label>Subject</label>
                    <div className="input-box">
                      <input
                        type="text"
                        className={`form-control theme-input ${
                          errors.subject ? "is-invalid" : ""
                        }`}
                        placeholder="Enter Subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                      <FontAwesomeIcon icon={faPen} />
                      {errors.subject && (
                        <small className="text-danger">{errors.subject}</small>
                      )}
                    </div>
                  </div>
                  <div className="theme-input-box">
                    <label>Message</label>
                    <div className="input-box">
                      <textarea
                        cols="8"
                        rows="4"
                        className={`form-control theme-input ${
                          errors.message ? "is-invalid" : ""
                        }`}
                        placeholder="Enter Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      {errors.message && (
                        <small className="text-danger">{errors.message}</small>
                      )}
                    </div>
                  </div>
                  <div className="btn-box mt-3">
                    <button
                      type="submit"
                      className="theme-btn"
                      disabled={loading}
                    >
                      {loading ? "Sending..." : "Send Message"}
                    </button>
                  </div>
                  {responseMessage && (
                    <p className="text-success">{responseMessage}</p>
                  )}
                </form>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="contact-main">
                <div className="left-about">
                  <img src={Contact} alt="contact" />
                  <img src={imageShape} alt="contact" className="shape-top" />
                  <img
                    src={imageShape}
                    alt="contact"
                    className="shape-bottom"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
