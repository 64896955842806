import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ResponsiveDropdownMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false); // State to track menu toggle
  const [activeDropdown, setActiveDropdown] = useState(null); // State to track active dropdown

  const handleToggle = () => {
    console.log(menuOpen);

    setMenuOpen(!menuOpen); // Toggle the menu state
  };
  const handleDropdownToggle = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown); // Toggle the specific dropdown
  };

  // Close dropdowns on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".dropdown")) {
        setActiveDropdown(null); // Close all dropdowns
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick); // Cleanup listener
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light p-0">
      <div className="container-fluid">
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={handleToggle} // Attach the toggle handler
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${menuOpen ? "show" : ""}`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <div className="close-menubar" onClick={handleToggle}>
              <FontAwesomeIcon icon={faXmark} />
            </div>
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about-us">
                About Us
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                // href="#"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the event from propagating to the window listener
                  handleDropdownToggle("products");
                }}
              >
                Products
              </a>
              <ul
                className={`dropdown-menu ${
                  activeDropdown === "products" ? "show" : ""
                }`}
              >
                <li onClick={() => handleDropdownToggle("products")}>
                  <Link className="dropdown-item" to="/products/forex">
                    Forex
                  </Link>
                </li>
                <li onClick={() => handleDropdownToggle("products")}>
                  <Link className="dropdown-item" to="/products/us-stocks">
                    US Stocks
                  </Link>
                </li>
                <li onClick={() => handleDropdownToggle("products")}>
                  <Link className="dropdown-item" to="/products/precious-metal">
                    Precious Metal
                  </Link>
                </li>
                <li onClick={() => handleDropdownToggle("products")}>
                  <Link className="dropdown-item" to="/products/index-future">
                    Index Future
                  </Link>
                </li>
                <li onClick={() => handleDropdownToggle("products")}>
                  <Link className="dropdown-item" to="/products/energy-future">
                    Energy Future
                  </Link>
                </li>
                <li onClick={() => handleDropdownToggle("products")}>
                  <Link
                    className="dropdown-item"
                    to="/products/contract-specification"
                  >
                    Contract Specification
                  </Link>
                </li>
                <li onClick={() => handleDropdownToggle("products")}>
                  <Link
                    className="dropdown-item"
                    to="/products/cryptocurrencies-cfd"
                  >
                    Cryptocurrencies CFD
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDropdownToggle("accounts");
                }}
              >
                Accounts
              </Link>
              <ul
                className={`dropdown-menu ${
                  activeDropdown === "accounts" ? "show" : ""
                }`}
              >
                <li onClick={() => handleDropdownToggle("accounts")}>
                  <Link className="dropdown-item" to="/accounts">
                    Accounts
                  </Link>
                </li>
                <li onClick={() => handleDropdownToggle("accounts")}>
                  <Link className="dropdown-item" to="/accounts/account-types">
                    Account Types
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDropdownToggle("platforms");
                }}
              >
                Platforms
              </a>
              <ul
                className={`dropdown-menu ${
                  activeDropdown === "platforms" ? "show" : ""
                }`}
              >
                <li onClick={() => handleDropdownToggle("platforms")}>
                  <Link className="dropdown-item" to="/platforms/mt5">
                    MetaTrader 5 (MT5) Platform
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDropdownToggle("partnership");
                }}
              >
                Partnership
              </a>
              <ul
                className={`dropdown-menu ${
                  activeDropdown === "partnership" ? "show" : ""
                }`}
              >
                <li onClick={() => handleDropdownToggle("partnership")}>
                  <Link
                    className="dropdown-item"
                    to="/partnership/upforex-agent"
                  >
                    Upforex Agent
                  </Link>
                </li>
                <li onClick={() => handleDropdownToggle("partnership")}>
                  <Link
                    className="dropdown-item"
                    to="/partnership/partnership-programs"
                  >
                    Partnership Programs
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDropdownToggle("resources");
                }}
              >
                Resources
              </a>
              <ul
                className={`dropdown-menu ${
                  activeDropdown === "resources" ? "show" : ""
                }`}
              >
                <li onClick={() => handleDropdownToggle("resources")}>
                  <Link
                    className="dropdown-item"
                    to="/resources/introduction-to-forex"
                  >
                    Introduction To Forex
                  </Link>
                </li>
                <li onClick={() => handleDropdownToggle("resources")}>
                  <Link className="dropdown-item" to="/resources/trading-rules">
                    Trading Rules
                  </Link>
                </li>
                <li onClick={() => handleDropdownToggle("resources")}>
                  <Link
                    className="dropdown-item"
                    to="/resources/trading-glossary"
                  >
                    Trading Glossary
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact-us">
                Contact Us
              </Link>
            </li>
          </ul>
          <button className="btn join-now-btn" type="button">
            Join Now
          </button>
        </div>
      </div>
    </nav>
  );
};

export default ResponsiveDropdownMenu;
