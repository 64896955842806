import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Font Awesome component
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../components/common/PageTitle";
import { Link } from "react-router-dom";

const AccountTypes = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Accounts" },
    { label: "Account Types" },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Account Types" breadcrumbs={breadcrumbs} />
      <div className="product-page-info-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div class="table-responsive account-types-table">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>ACCOUNT TYPE</th>
                      <th>Standard</th>
                      <th>Pro-ECN</th>
                      <th>Elite-ECN</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Currencies</td>
                      <td>USD, EUR, GBP or SGD</td>
                      <td>USD, EUR, GBP or SGD</td>
                      <td>USD, EUR, GBP or SGD</td>
                    </tr>
                    <tr>
                      <td>
                        Leverage<sup>(1)</sup>
                      </td>
                      <td>Upto 1:300</td>
                      <td>Upto 1:300</td>
                      <td>Upto 1:300</td>
                    </tr>
                    <tr>
                      <td>Spread</td>
                      <td>From 0.1 Pips</td>
                      <td>0* Pips</td>
                      <td>0* Pips</td>
                    </tr>
                    <tr>
                      <td>Commission</td>
                      <td>0</td>
                      <td>
                        $7/Round lot<sup>(3)</sup>
                      </td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>Minimum Transaction Size</td>
                      <td>0.01 Lot</td>
                      <td>0.01 Lot</td>
                      <td>0.01 Micro Lot</td>
                    </tr>
                    <tr>
                      <td>Instruments Available</td>
                      <td>Forex, Metals, Futures, Shares, and Cash CFDs</td>
                      <td>Forex, Metals, Futures, Shares, and Cash CFDs</td>
                      <td>Forex, Metals, Futures, Shares, and Cash CFDs</td>
                    </tr>
                    <tr>
                      <td>Stop/Limit Levels</td>
                      <td>No Minimum for Forex & Metals</td>
                      <td>No Minimum for Forex & Metals</td>
                      <td>No Minimum for Forex & Metals</td>
                    </tr>
                    <tr>
                      <td>Instruments Specifications</td>
                      <td>
                        <Link>View Contract Specifications</Link>
                      </td>
                      <td>
                        <Link>View Contract Specifications</Link>
                      </td>
                      <td>
                        <Link>View Contract Specifications</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Available to</td>
                      <td>Globally</td>
                      <td>Globally</td>
                      <td>Globally</td>
                    </tr>
                    <tr>
                      <td>
                        Credit Bonus<sup>(5)</sup>
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                    </tr>
                    <tr>
                      <td>Expert Advisor</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                    </tr>
                    <tr>
                      <td>Real Time News Feed</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                    </tr>
                    <tr>
                      <td>MT5 Mobile Trading Platform</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                    </tr>
                    <tr>
                      <td>Segregated accounts</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                    </tr>
                    <tr>
                      <td>Charts & Platforms</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                    </tr>
                    <tr>
                      <td>Free video tutorials</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                    </tr>
                    <tr>
                      <td>SMS Updates</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Swap free<sup>(6)</sup>
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                    </tr>
                    <tr>
                      <td>Complementary Premium Services</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} />
                      </td>
                    </tr>
                    <tr>
                      <td>Execution</td>
                      <td>Market Execution</td>
                      <td>Market Execution</td>
                      <td>Market Execution</td>
                    </tr>
                    <tr>
                      <td>
                        Stop out level<sup>(7)</sup>
                      </td>
                      <td>
                        0%<sup>(8)</sup>
                      </td>
                      <td>
                        0%<sup>(8)</sup>
                      </td>
                      <td>
                        0%<sup>(8)</sup>
                      </td>
                    </tr>
                    <tr>
                      <td>Trading Platforms</td>
                      <td>MT5: Windows, Mac, iOS, Android</td>
                      <td>MT5: Windows, Mac, iOS, Android</td>
                      <td>MT5: Windows, Mac, iOS, Android</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountTypes;
