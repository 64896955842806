import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Check if this is a page refresh or navigation
    const isRefresh = sessionStorage.getItem("lastPath") === pathname;

    if (!isRefresh) {
      // Scroll to top only for navigation
      window.scrollTo(0, 0);
    }

    // Save the current path in sessionStorage
    sessionStorage.setItem("lastPath", pathname);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
