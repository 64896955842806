import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL || "";

// Create an Axios instance
const apiClient = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Add response interceptor for centralized error handling
apiClient.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const errorMessage =
      error.response?.data?.message ||
      error.message ||
      "An unexpected error occurred.";
    return Promise.reject(new Error(errorMessage));
  }
);

export default {
  post: (url, data, config = {}) => apiClient.post(url, data, config),
};
